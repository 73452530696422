$primary-color: #2B3990;
$secondary-color: #1f2967;

.hero-main {
    margin-bottom: 3rem !important;
    overflow: hidden; /* Добавляем overflow: hidden для контейнера слайдера */
    //max-height: 600px;

    .container {
        position: relative;
    }

    .swiper-slide {
        width: 100% !important;
        height: 600px !important;
        background-size: cover !important;
        background-repeat: no-repeat !important;
        background-position: center center !important;

        @media screen and (max-width: 980px) {
            background-size: 200% !important;
            height: 400px !important;
            //height: 400px !important;
        }

    }

    .description {
        //height: 100%;
        //position: relative;
        padding-right: 1rem !important;

        @media screen and (min-width: 980px) {
            padding-right: 11rem !important;
        }

        h1 {
            font-family: 'Lora', sans-serif;
            font-style: normal;
            font-weight: 700;
            font-size: 48px;
            color: #fff;
            margin-bottom: 20px;

            @media screen and (max-width: 560px) {
                font-size: 32px;
            }
        }

        .info {
            h2 {
                font-family: 'Lora', sans-serif;
                font-style: normal;
                font-weight: 700;
                font-size: 38px;
                color: #fff;
                margin-bottom: 20px;


                @media screen and (max-width: 560px) {
                    font-size: 26px;
                }
            }

        }

    }



    /* Стили для контейнера с текстом в слайде */
    .slide-content {

        //@media screen and (max-width: 560px) {
        //  position: relative;
        //}
    }

    .slide-content {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;


        //@media screen and (max-width: 560px) {
        //    height: 700px;
        //}
    }

    .feedback {
        display: flex;
        align-items: center;
        margin-top: 5rem !important;

        //position: absolute;
        //left: 0;
        //bottom: 0;

        @media screen and (max-width: 560px) {
            display: block;
            align-items: center;
            margin-top: 1rem !important;
        }

        button {
            min-width: 185px;
            text-align: center;
            padding: 15px 20px;
            color: #fff;
            border: none;
            font-family: 'Lora', sans-serif;
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            display: flex;
            align-items: center;
            background: rgba(43, 57, 144, 0.8);
            border-radius: 8px;

            transition: 200ms background-color;

            &:hover {
                background: $secondary-color;
            }

            @media screen and (max-width: 560px) {
                width: 100%;
                justify-content: center;
                margin-bottom: 20px;
            }
        }

        .phone-box {
            margin-left: 50px;
            svg {
                padding-right: 15px;

                @media screen and (max-width: 768px) {
                    path {
                        fill: $secondary-color;
                    }
                }

            }

            .span-1 {
                font-family: 'Lora', sans-serif;
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                color: #fff;

                @media screen and (max-width: 768px) {
                    color: $secondary-color;
                }
            }

            .span-2 a {
                font-family: 'Lora', sans-serif;
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 19px;
                color: #fff;
                text-decoration: none;

                @media screen and (max-width: 768px) {
                    color: $secondary-color;
                }

            }
        }
    }

    .presentation {
        .btn {
            margin-top: 32px;

            &.btn-presentation {
                max-width: 300px;
                text-align: center;
                padding: 15px 20px;
                color: $secondary-color;
                border: none;
                font-family: 'Lora', sans-serif;
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                display: grid;
                align-items: center;
                background: rgba(255, 255, 255, 0.8);
                border-radius: 8px;

                transition: 200ms background-color;

                &:hover {
                    background: rgba(255, 255, 255, 1);
                }

                @media screen and (max-width: 560px) {
                    width: 100%;
                    max-width: 100%;
                    justify-content: center;
                    margin-bottom: 20px;
                }
            }
        }

    }


}
