.services-page {

    padding: 0;

    h1 {
        color: #343434;
        font-family: 'Lora', sans-serif;
        font-size: 64px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;

        margin-bottom: 30px;

        @media screen and (max-width: 560px) {
            font-size: 28px;
        }


    }

    .services-page-box {
        padding: 32px;
        background: #ffffff;
        margin-bottom: 30px;

        .items {
            font-family: 'Lora', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            color: #343434;


            .header {
                &-item {
                    display: flex;
                    align-items: center;

                    svg {
                        margin-right: 16px;
                    }
                    h2 {
                        color: #343434;
                        font-family: 'Lora', sans-serif;
                        font-size: 24px;
                        font-style: normal;
                        font-weight: 700;

                    }
                }

                p {
                    color: #343434;
                    font-family: 'Lora', sans-serif;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    margin-top: 32px;
                }

            }


            &-body {
                margin-top: 32px;

                &__item {
                    margin-bottom: 32px;
                }

                &__title {
                    display: flex;
                    align-items: center;

                    margin-bottom: 16px;

                    svg {
                        max-width: 13px !important;
                        max-height: 24px !important;
                        min-width: 13px !important;
                        min-height: 24px !important;
                        margin-right: 16px;
                    }

                    h3 {
                        color: #343434;
                        font-family: 'Lora', sans-serif;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 700;
                        margin-bottom: 0;
                    }
                }

                p {
                    color: #343434;
                    font-family: 'Lora', sans-serif;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    margin-bottom: 0;
                }


            }

            h3 {
                color: #343434;
                font-family: 'Lora', sans-serif;
                font-size: 20px;
                font-style: normal;
                font-weight: 600;
            }

            p {
                color: #343434;
                font-family: 'Lora', sans-serif;
                font-size: 15px;
                font-style: normal;
                font-weight: 400;
            }
        }

        .button {
            text-align: center;
            margin-top: 32px;
            margin-bottom: 32px;

            display: flex;
            justify-content: center;

            .btn {
                &.btn-primary {
                    min-width: 185px;
                    text-align: center;
                    padding: 15px 20px;
                    color: #fff;
                    border: none;
                    font-family: 'Lora', sans-serif;
                    font-style: normal;
                    font-weight: 700;
                    font-size: 16px;
                    display: flex;
                    align-items: center;
                    transition: 200ms background-color;

                    background: rgba(43, 57, 144, 0.8);
                    border-radius: 8px;

                    &:hover {
                        background: $secondary-color;
                    }

                    @media screen and (max-width: 560px) {
                        width: 100%;
                        justify-content: center;
                        margin-bottom: 20px;
                    }
                }
            }

        }

    }
}
