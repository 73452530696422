.news {
    //padding: 32px;

    h2 {
        color: #343434;
        font-family: 'Lora', sans-serif;
        font-size: 64px;
        font-style: normal;
        font-weight: 700;
        //padding-top: 30px;
        margin-bottom: 32px;
        //padding-left: 20px;

        @media screen and (max-width: 560px) {
            font-size: 28px;
        }

    }

    .card {
        width: 100%;
        height: 100%;
        border: none;
        border-radius: 0;
        padding: 10px;
        margin: 0;

        color: #343434;
        overflow: hidden;

        &:hover {
            background: rgba(43, 57, 144, 0.8);
            border-radius: 8px;

            .card-header {
                img {
                    transform: scale(1.1);

                }
            }

            .card-body {
                h4 {
                    color: #fff;
                }

                p {
                    color: #fff;
                }
            }

            .card-footer {
                border: none;
                background: transparent;

                a {
                    color: #fff;

                    svg {
                        display: flex;
                        justify-content: flex-end;

                        g {
                            path {
                                fill: #fff;
                            }
                        }

                    }
                }
            }


        }

        &-header {
            border: none;
            padding: 0;
            background: transparent;
            height: 180px;
            display: flex;
            align-items: center;
            overflow: hidden;

            img {
                width: 100%;
                transition: 300ms all;

                @media screen and (max-width: 560px) {
                    width: 100%;
                }

            }


        }

        &-body {
            padding: 20px 0;

            h4 {
                font-family: 'Lora', sans-serif;
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 20px;
                color: #343434;
            }

            p {
                font-family: 'Lora', sans-serif;
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                color: #969696;
            }
        }

        &-footer {
            border: none;
            padding-top: 20px;
            padding-bottom: 20px;
            background: transparent;

            a {
                font-family: 'Lora', sans-serif;
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                display: flex;
                align-items: center;
                text-align: center;
                color: #969696;

                div {
                    width: 100%;
                    position: relative;

                    svg {
                        position: absolute;
                        top: -10px;
                        right: 0;

                    }
                }

            }
        }
    }

}
