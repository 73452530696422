.charity-foundation {

    padding: 0;

    &-box {
        padding: 32px;
        background: #ffffff;
        margin-bottom: 30px;

        .items {
            font-family: 'Lora', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            color: #343434;


            .header {
                &-item {
                    display: flex;
                    align-items: center;

                    svg {
                        margin-right: 16px;
                    }
                    h2 {
                        color: #343434;
                        font-family: 'Lora', sans-serif;
                        font-size: 24px;
                        font-style: normal;
                        font-weight: 700;

                    }
                }


            }


            &-body {
                margin-top: 24px;

                p {
                    color: #343434;
                    font-family: 'Lora', sans-serif;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: normal;
                    margin-bottom: 0;

                    span {
                        font-family: 'Lora', sans-serif;
                        font-weight: 700;

                    }
                }

            }


        }

    }

    .button {
        text-align: center;
        margin-top: 48px;
        margin-bottom: 16px;
        display: flex;
        justify-content: center;

        .btn {
            &.btn-primary {
                min-width: 185px;
                text-align: center;
                padding: 15px 20px;
                color: #fff;
                border: none;
                font-family: 'Lora', sans-serif;
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                display: flex;
                justify-content: center;
                align-items: center;
                background: rgba(43, 57, 144, 0.8);
                border-radius: 8px;
                transition: 200ms background-color;

                &:hover {
                    background: $secondary-color;
                }

                @media screen and (max-width: 560px) {
                    width: 100%;
                    justify-content: center;
                    margin-bottom: 20px;
                }
            }
        }

    }

}
